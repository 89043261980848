import React, { memo } from '../../lib/teact/teact';
import { getActions, withGlobal } from '../../global';

import useLang from '../../hooks/useLang';

import Modal from '../ui/Modal';
import ModalHeader from '../ui/ModalHeader';
import Content from './Content';

import styles from './ReceiveModal.module.scss';

type StateProps = {
  isOpen?: boolean;
};

function ReceiveModal({
  isOpen,
}: StateProps) {
  const {
    closeReceiveModal,
  } = getActions();

  const lang = useLang();

  return (
    <Modal
      isOpen={isOpen}
      dialogClassName={styles.modalDialog}
      nativeBottomSheetKey="receive"
      onClose={closeReceiveModal}
    >
      <ModalHeader title={lang('Receive')} className={styles.receiveHeader} onClose={closeReceiveModal} />
      <Content
        isOpen={isOpen}
        onClose={closeReceiveModal}
      />
    </Modal>
  );
}

export default memo(withGlobal((global): StateProps => {
  return {
    isOpen: global.isReceiveModalOpen,
  };
})(ReceiveModal));
