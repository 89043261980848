import React, {
  memo, useMemo, useRef, useState,
} from '../../lib/teact/teact';
import { getActions, withGlobal } from '../../global';

import { type ApiDapp } from '../../api/types';
import { SettingsState } from '../../global/types';

import { selectCurrentAccountState } from '../../global/selectors';
import buildClassName from '../../util/buildClassName';

import useEffectWithPrevDeps from '../../hooks/useEffectWithPrevDeps';
import useHorizontalScroll from '../../hooks/useHorizontalScroll';
import useLang from '../../hooks/useLang';

import Transition from '../ui/Transition';
import DappFeedItem from './DappFeedItem';

import styles from './Dapp.module.scss';

interface StateProps {
  dapps: ApiDapp[];
  dappLastOpenedDatesByOrigin?: Record<string, number>;
}

type DappWithLastOpenedDate = ApiDapp & { lastOpenedAt?: number };

function compareDapps(a: DappWithLastOpenedDate, b: DappWithLastOpenedDate) {
  const aLastOpened = a.lastOpenedAt || 0;
  const bLastOpened = b.lastOpenedAt || 0;

  if (aLastOpened !== bLastOpened) {
    return bLastOpened - aLastOpened;
  }

  return b.connectedAt - a.connectedAt;
}

function didDappsOrderChanged(prevDapps: ApiDapp[], newDapps: ApiDapp[]) {
  if (prevDapps.length !== newDapps.length) {
    return true;
  }

  for (let i = 0; i < prevDapps.length; i++) {
    if (prevDapps[i].origin !== newDapps[i].origin) {
      return true;
    }
  }

  return false;
}

const MAX_DAPPS_FOR_MINI_MODE = 3;

function DappFeed({ dapps: dappsFromState, dappLastOpenedDatesByOrigin = {} }: StateProps) {
  const { openSettingsWithState } = getActions();

  const dapps: DappWithLastOpenedDate[] = useMemo(() => {
    return dappsFromState.slice().map(
      (dapp) => ({ ...dapp, lastOpenedAt: dappLastOpenedDatesByOrigin[dapp.origin] }),
    ).sort(compareDapps);
  }, [dappLastOpenedDatesByOrigin, dappsFromState]);

  const [activeKey, setActiveKey] = useState(0);
  useEffectWithPrevDeps(([prevDapps]) => {
    if (dapps.length === (prevDapps?.length ?? 0) && !(prevDapps && didDappsOrderChanged(prevDapps, dapps))) {
      return;
    }

    setActiveKey((value) => value + 1);
  }, [dapps]);

  const mode = dapps.length > MAX_DAPPS_FOR_MINI_MODE ? 'tile' : 'mini';

  const lang = useLang();

  function openSettings() {
    openSettingsWithState({ state: SettingsState.Dapps });
  }

  // eslint-disable-next-line no-null/no-null
  const containerRef = useRef<HTMLDivElement>(null);
  useHorizontalScroll({
    containerRef,
    isDisabled: dapps.length === 0,
    shouldPreventDefault: true,
    contentSelector: `.${styles.feed}`,
  });

  const isMiniMode = mode === 'mini';
  const transitionClassName = isMiniMode ? styles.feedTransitionMini : styles.feedTransitionTile;
  const iconWrapperClassName = isMiniMode ? styles.feedSettingsIconWrapperMini : styles.feedSettingsIconWrapperTile;

  function renderDapp(dapp: DappWithLastOpenedDate) {
    const {
      iconUrl, name, url, origin,
    } = dapp;

    return (
      <DappFeedItem
        key={origin}
        iconUrl={iconUrl}
        name={name}
        url={url}
        mode={mode}
        origin={origin}
      />
    );
  }

  return (
    <Transition
      name="zoomFade"
      ref={containerRef}
      shouldCleanup
      activeKey={activeKey}
      className={
        buildClassName(
          styles.feedTransition, dapps.length ? transitionClassName : styles.feedTransitionEmpty, 'dapps-feed',
        )
      }
    >
      <div className={buildClassName(styles.feed, isMiniMode ? styles.feedMini : styles.feedTiles)}>
        {dapps?.map(renderDapp)}
        {!!dapps.length && (
          <div
            className={styles.feedSettingsIconContainer}
            onClick={openSettings}
            title={isMiniMode ? lang('Settings') : undefined}
          >
            <div className={buildClassName(styles.feedSettingsIconWrapper, iconWrapperClassName)}>
              <i className="icon-params" aria-hidden />
            </div>
            {!isMiniMode && <span className={styles.feedSettingsLabel}>{lang('Settings')}</span>}
          </div>
        )}
      </div>
    </Transition>
  );
}

export default memo(withGlobal((global): StateProps => {
  const { dapps } = global.settings;
  const { dappLastOpenedDatesByOrigin } = selectCurrentAccountState(global) || {};
  return { dapps, dappLastOpenedDatesByOrigin };
})(DappFeed));
