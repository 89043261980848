import React, { memo } from '../../../../lib/teact/teact';

import { ANIMATED_STICKER_BIG_SIZE_PX, ANIMATED_STICKER_SMALL_SIZE_PX } from '../../../../config';
import buildClassName from '../../../../util/buildClassName';
import { ANIMATED_STICKERS_PATHS } from '../../../ui/helpers/animatedAssets';

import useLang from '../../../../hooks/useLang';

import AnimatedIconWithPreview from '../../../ui/AnimatedIconWithPreview';

import styles from './NewWalletGreeting.module.scss';

interface Props {
  isActive?: boolean;
  isMutlichain?: boolean;
  mode: 'panel' | 'emptyList';
}

function NewWalletGreeting({ isActive, isMutlichain, mode }: Props) {
  const lang = useLang();

  return (
    <div className={buildClassName(styles.container, styles[mode])}>
      <AnimatedIconWithPreview
        play={isActive}
        tgsUrl={ANIMATED_STICKERS_PATHS.hello}
        previewUrl={ANIMATED_STICKERS_PATHS.helloPreview}
        nonInteractive
        noLoop={false}
        size={mode === 'emptyList' ? ANIMATED_STICKER_BIG_SIZE_PX : ANIMATED_STICKER_SMALL_SIZE_PX}
      />

      <div className={styles.text}>
        <p className={styles.header}>
          {lang(isMutlichain ? 'You have just created a new multichain wallet' : 'You have just created a new wallet')}
        </p>
        <p className={styles.description}>
          {lang(
            isMutlichain
              ? 'Now you can transfer tokens from your TON and TRON wallets.'
              : 'You can now transfer your tokens from another wallet or exchange.',
          )}
        </p>
      </div>
    </div>
  );
}

export default memo(NewWalletGreeting);
